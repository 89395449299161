// partnership utm codes
export const utmFree = [
  "davis-tech",
  "honestjobs_jul24_az",
  "demo",
  "website_rec-month",
  "watson-quickadd",
  "watson-quickadd-partnership",
];
export const REFERRAL_CLIENT = "referral-client";
export const REFERRALCLIENT = "referralclient";

export const PARTNERSHIP_ = "partnership_";

export const _PROMO = "_promo";

export const isFreeUtm = (utm_code) => {
  return (
    utmFree.includes(utm_code) ||
    (utm_code && utm_code.startsWith(PARTNERSHIP_)) ||
    (utm_code && utm_code.endsWith(_PROMO))
  );
};
